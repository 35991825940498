@import url(https://fonts.googleapis.com/css?family=Roboto:700i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Quicksand:300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:300,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata&display=swap);
@font-face {
  font-family: 'IBM Plex Mono';
  src: url(/static/media/IBMPlexMono-Light.cddd40b3.ttf) format('truetype');
}
@font-face {
  font-family: 'Segoe UI';
  src: url(/static/media/SegoeUI-Bold.65099f98.ttf) format('truetype');
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



